var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('CToaster',{attrs:{"autohide":3000}},[_vm._l((_vm.infoList),function(info){return [_c('CToast',{key:info.message,attrs:{"show":true,"header":info.header,"color":info.color}},[_vm._v(" "+_vm._s(info.message)+". ")])]})],2),_c('CRow',[_c('CCol',[_c('CCard',[_c('CCardHeader',[_c('strong',[_vm._v("Rental Product")]),_c('a',{class:_vm.getBadgeClass(),attrs:{"href":"","target":"_blank"}},[_vm._v(" "+_vm._s(_vm.obj.stateDescription)+" ")]),_c('div',{staticClass:"card-header-actions"},[_c('CDropdown',{staticClass:"m-2 d-inline-block tour-cdropdown",attrs:{"placement":"bottom-end","toggler-text":"Action","color":"light","size":"sm"}},[_c('CDropdownItem',{on:{"click":function($event){return _vm.onConvertToInvoice(_vm.obj)}}},[_vm._v("Duplicate")]),_c('CDropdownDivider'),_c('CDropdownHeader',[_vm._v("Change Status To:")]),_vm._l((_vm.statuses),function(status){return [_c('CDropdownItem',{on:{"click":function($event){return _vm.changeState(status)}}},[_vm._v(_vm._s(status.name))])]})],2)],1)]),_c('CCardBody',[_c('CForm',[_c('CInput',{attrs:{"label":"Name"},model:{value:(_vm.obj.name),callback:function ($$v) {_vm.$set(_vm.obj, "name", $$v)},expression:"obj.name"}}),_c('CInput',{attrs:{"label":"Type"},model:{value:(_vm.obj.type),callback:function ($$v) {_vm.$set(_vm.obj, "type", $$v)},expression:"obj.type"}}),_c('CTextarea',{attrs:{"label":"Description","placeholder":"","rows":"4"},model:{value:(_vm.obj.description),callback:function ($$v) {_vm.$set(_vm.obj, "description", $$v)},expression:"obj.description"}}),_c('CSelect',{attrs:{"value":_vm.selectedColorName,"options":_vm.presetColors.map((color) => ({
                    value: color.name,
                    label: color.name,
                    code: color.code,
                    name: color.name,
                    label: color.label,
                  })),"label":"Select Color"},on:{"update:value":function($event){_vm.selectedColorName=$event},"change":_vm.updateSelectedColor},scopedSlots:_vm._u([{key:"option",fn:function({ option }){return [_c('div',{staticClass:"color-option"},[_c('span',{staticClass:"color-preview",style:({ backgroundColor: _vm.getColorCode(option.value) })}),_vm._v(" "+_vm._s(option.label)+" ")])]}}])}),(_vm.obj.color)?_c('div',{style:({
                  backgroundColor: _vm.obj.color,
                  width: '50px',
                  height: '30px',
                  borderRadius: '5px',
                  marginTop: '10px',
                  border: '1px solid #ddd',
                })}):_vm._e()],1)],1),_c('CCardFooter',[_c('CButton',{attrs:{"type":"submit","color":"light"},on:{"click":_vm.submit}},[_vm._v(" Save")])],1)],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }